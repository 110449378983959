import { render, staticRenderFns } from "./ossUploader.vue?vue&type=template&id=66ea3be0&scoped=true"
import script from "./ossUploader.vue?vue&type=script&lang=ts"
export * from "./ossUploader.vue?vue&type=script&lang=ts"
import style0 from "./ossUploader.vue?vue&type=style&index=0&id=66ea3be0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66ea3be0",
  null
  
)

export default component.exports